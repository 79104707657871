import React from 'react';

import { Switch, Route } from 'react-router-dom';
import Main from '../pages/Main';
import NoMatch from '../pages/NoMatch';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route component={NoMatch} />
  </Switch>
);

export default Routes;
