import { createGlobalStyle } from 'styled-components';
import Github from '../assets/images/github.png';
import LinkedIn from '../assets/images/linkedin.png';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #343741;
    font-family: 'Fira Code', monospace;
    text-align: center;
    color: #AAAAAA;
  }

  h1 {
    font-size: 15rem;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 5rem;
    margin: 0;
    padding: 0;
  }

  div.content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  div.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 100%;
  }

  ul {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }

  li {
    list-style: none;

    a {
      color: #ffffff;
      font-size: 1.5rem;
      padding-top: 120px;
    }

    &.github a {
      background: url(${Github}) no-repeat top center;
      background-size: 110px;
    }

    &.linkedin a {
      background: url(${LinkedIn}) no-repeat top center;
      background-size: 110px;
    }
  }
`;

export default GlobalStyle;
