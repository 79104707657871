import {
  all,
  // takeLatest,
} from 'redux-saga/effects';

// import { Types as BannersTypes } from "../ducks/banners";

// import { getBanners } from "./banners";

export default function* rootSaga() {
  yield all([
    // takeLatest(BannersTypes.GET_REQUEST, getBanners),
  ]);
}
