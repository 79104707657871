import React from 'react';

// import { Container } from './styles';

const Main = () => (
  <div className="content">
    <h1>0</h1>
    <h2>Zero</h2>

    <div className="links">
      <ul>
        <li className="github"><a target="_blank" href="https://github.com/jremerich">/jremerich</a></li>
        <li className="linkedin"><a target="_blank" href="https://www.linkedin.com/in/jos%C3%A9-roberto-emerich-junior/">/josé-roberto-emerich-junior</a></li>
      </ul>
    </div>
  </div>
);

export default Main;
